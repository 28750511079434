import { Image, Link, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { Control, Scenario } from 'src/api/types';
import { Header } from './Header';
import { pdfStyles } from '../pdfStyles';
import { Footer } from './Footer';
import { NoData } from './NoData';
import { TitleLink } from './TitleLink';
import { ScenariosTable } from './ScenariosTable';
import { formatDate, numeralFormat } from 'src/utils/misc';
import { formatUrl } from 'src/utils/misc';
import { extLinkImg } from '../imgData';
import { ColorSet } from 'src/redux/reducers/theme';

interface RmpPageProps {
  control: Control;
  scenarios?: Scenario[];
  onRender: (pageNumber: number) => void;
  endComponent?: React.ReactNode;
  footerComponent?: React.ReactNode;
  titleSmall?: boolean;
  colors: ColorSet;
}

export const RmpPage: React.FC<RmpPageProps> = ({
  control,
  scenarios,
  onRender,
  endComponent,
  footerComponent,
  colors,
}) => {
  const relatedScenarios =
    control.scenarios && control.scenarios.length > 0
      ? control.scenarios
      : scenarios?.filter((scenario) =>
          //@ts-ignore
          scenario.controls?.some((scenarioControl) => scenarioControl.ux_id === control.ux_id),
        ) || [];

  return (
    <Page size={'A4'} style={pdfStyles.page}>
      <Header endComponent={endComponent} colors={colors} />

      {/* @ts-ignore */}
      <View render={(data) => onRender(data.pageNumber)} />
      <View style={pdfStyles.section}>
        <TitleLink
          title={control.name}
          intLinkId={control.name.toLowerCase()}
          // @ts-ignore
          extLink={`risk-management/control/${control.ux_id || control.id}`}
          colors={colors}
        />

        <View style={pdfStyles.spacer8} />
        <View style={pdfStyles.spacer4} />
        <View style={{ flexDirection: 'row' }}>
          <View style={{ width: 120, marginRight: 20 }}>
            <Text style={pdfStyles.label}>STRATEGY</Text>
            {control.strategy ? <Text style={pdfStyles.value}>{control.strategy}</Text> : <NoData colors={colors} />}
          </View>

          <View style={{ width: 120, marginRight: 20 }}>
            <Text style={pdfStyles.label}>STATUS</Text>
            <View style={pdfStyles.spacer1} />
            {control.status ? <Text style={pdfStyles.value}>{control.status}</Text> : <NoData colors={colors} />}
          </View>
          <View style={{ width: 120, marginRight: 20 }}>
            <Text style={pdfStyles.label}>LINK</Text>
            <View style={pdfStyles.spacer1} />
            {control.source && control.url_name ? (
              <Link
                src={formatUrl(control.source)}
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  textDecoration: 'none',
                }}
              >
                <Text
                  style={{
                    ...pdfStyles.value,
                    textDecoration: 'underline',
                    marginRight: 6,
                  }}
                >
                  {control.url_name}
                </Text>
                <Image src={extLinkImg} style={{ width: 8, height: 8 }} />
              </Link>
            ) : control.source ? (
              <Link
                src={formatUrl(control.source)}
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  textDecoration: 'none',
                }}
              >
                <Text
                  style={{
                    ...pdfStyles.value,
                    textDecoration: 'underline',
                    color: 'blue',
                    marginRight: 6,
                  }}
                >
                  {control.source}
                </Text>
                <Image src={extLinkImg} style={{ width: 8, height: 8 }} />
              </Link>
            ) : control.url_name ? (
              <Text style={pdfStyles.value}>{control.url_name}</Text>
            ) : (
              <NoData colors={colors} />
            )}
          </View>
        </View>

        <View style={pdfStyles.spacer8} />

        <View style={{ flexDirection: 'row' }}>
          <View style={{ width: 120, marginRight: 20 }}>
            <Text style={pdfStyles.label}>OWNER</Text>
            {control.owner?.text ? (
              <Text style={pdfStyles.value}>{control.owner.text}</Text>
            ) : (
              <NoData colors={colors} />
            )}
          </View>
          <View style={{ width: 120, marginRight: 20 }}>
            <Text style={pdfStyles.label}>ANNUAL COST</Text>
            <View style={pdfStyles.spacer1} />
            {control.annual_cost ? (
              <Text style={pdfStyles.value}>{numeralFormat(control.annual_cost)}</Text>
            ) : (
              <NoData colors={colors} />
            )}
          </View>
          <View style={{ marginRight: 20 }}>
            <Text style={pdfStyles.label}>VENDOR</Text>
            <View style={pdfStyles.spacer1} />
            {control.vendor ? <Text style={pdfStyles.value}>{control.vendor}</Text> : <NoData colors={colors} />}
          </View>
        </View>

        <View style={pdfStyles.spacer8} />

        <View style={{ flexDirection: 'row' }}>
          <View style={{ width: 120, marginRight: 20 }}>
            <Text style={pdfStyles.label}>FRAMEWORK ID</Text>
            <View style={pdfStyles.spacer1} />
            {control.controlId ? <Text style={pdfStyles.value}>{control.controlId}</Text> : <NoData colors={colors} />}
          </View>
          <View style={{ width: 320, marginRight: 20 }}>
            <Text style={pdfStyles.label}>FRAMEWORK</Text>
            <View style={pdfStyles.spacer1} />
            {control.frameworkLibrary_name ? (
              <Text style={pdfStyles.value}>{control.frameworkLibrary_name}</Text>
            ) : (
              <NoData colors={colors} />
            )}
          </View>
        </View>

        <View style={pdfStyles.spacer8} />
        <View style={{ flexDirection: 'row' }}>
          <View style={{ width: 120, marginRight: 20 }}>
            <Text style={pdfStyles.label}>EXPECTED LIVE DATE</Text>
            <View style={pdfStyles.spacer1} />
            {control.expected_active_date ? (
              <Text style={pdfStyles.value}>{formatDate(control.expected_active_date, { emptyValue: '-' })}</Text>
            ) : (
              <NoData colors={colors} />
            )}
          </View>
          <View style={{ width: 120, marginRight: 20 }}>
            <Text style={pdfStyles.label}>ACTUAL LIVE DATE</Text>
            <View style={pdfStyles.spacer1} />
            {control.actual_active_date ? (
              <Text style={pdfStyles.value}>{formatDate(control.actual_active_date, { emptyValue: '-' })}</Text>
            ) : (
              <NoData colors={colors} />
            )}
          </View>
          <View style={{ width: 120, marginRight: 20 }}>
            <Text style={pdfStyles.label}>REVIEW DATE</Text>
            <View style={pdfStyles.spacer1} />
            {control.review_date ? (
              <Text style={pdfStyles.value}>{formatDate(control.review_date, { emptyValue: '-' })}</Text>
            ) : (
              <NoData colors={colors} />
            )}
          </View>
          <View style={{ marginRight: 20 }}>
            <Text style={pdfStyles.label}>LAST EDITED</Text>
            <View style={pdfStyles.spacer1} />
            {control.updated_at ? (
              <Text style={pdfStyles.value}>
                {formatDate(control.updated_at, { emptyValue: '-', formatType: 'datetime' })}
              </Text>
            ) : (
              <NoData colors={colors} />
            )}
          </View>
        </View>
        <View style={pdfStyles.spacer8} />

        <Text style={pdfStyles.label}>DESCRIPTION</Text>
        {control.description ? <Text style={pdfStyles.value}>{control.description}</Text> : <NoData colors={colors} />}
        <View style={pdfStyles.spacer8} />
        <View style={pdfStyles.spacer8} />
        {relatedScenarios.length > 0 ? (
          <ScenariosTable data={relatedScenarios} colors={colors} currentControlId={control.id} />
        ) : null}
      </View>
      <Footer footerComponent={footerComponent} colors={colors} />
    </Page>
  );
};
